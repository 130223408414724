import React from "react";
import * as  styles from "./blog.module.css";
import moment from "moment";
import {Link} from "gatsby";
export default function BlogCards({blogsData}) {
  return (
    <div className={`${styles.blog_cards_main}`}>
      <div className="container-el container-lg container-md d-flex flex-wrap justify-center item-center">
        {blogsData.allGhostPost.edges !== undefined
          ? blogsData.allGhostPost.edges.map((dt, index) => {
              return (
                <div
                  key={index}
                  className={`${styles.blog_card} flex-30 d-flex flex-column  bs-bb m-20`}
                >
                  <Link
                    to={dt.node.slug}
                    state={{data: dt.node}}
                    itemProp="url"
                    className='link-tag'
                  >
                    <div className={`${styles.blog_card_img}`}>
                      <img alt={dt.node.title} src={dt.node.feature_image} />
                    </div>
                    <div className={`${styles.blog_card_content} bs-bb`}>
                      <div className={`${styles.blog_card_text}`}>
                        <h4 className="mb-20" style={{fontFamily: `Montserrat, sans-serif`}}>
                          {moment(dt.node.published_at).format("MMMM DD, YYYY")}
                        </h4>
                        <a className="no-underline text-black" >
                          {dt.node.title}
                        </a>
                      </div>
                    </div>
                  </Link>
                </div>
              );
            })
          : null}
      </div>
    </div>
  );
}
