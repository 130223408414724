import React from "react";
import * as styles from "./blog.module.css";

export default function BlogFirst() {
  return (
    <div
      className={`${styles.blog_main} d-flex justify-center flex-column item-center`}
    >
      <div className={` ${styles.main_text} pl-30 pr-30 bs-bb text-center`}>
        <h2 className={`${styles.title} mb-30`}>
          Our <span className="text-zinc" style={{fontFamily: `Montserrat, sans-serif`}}>Blog</span>
        </h2>
        <p className={`${styles.desc} `} style={{fontFamily: `Montserrat, sans-serif`}}>
          Your resource for expert advice on product development and app
          building for startups.
        </p>
      </div>
    </div>
  );
}
