import React from "react";
import * as  styles from "./cover.module.css";


export default function CoverBanner({ title, titlegreen, desc, img }) {
  return (
    <div
      className={`${styles.service_cover} d-flex item-center index-1 ps-relative`}
    >
      <div className={`${styles.bg_art} ps-absolute `}>
        <img alt="ilustration" src={img} />
      </div>

      <div className="container-el container-lg  ">
        <div className={`${styles.cover_text} pl-30 pr-30 bs-bb `}>
          <h3 className={`${styles.cover_title} mb-30 `}>
            {title} <span className="text-zinc">{titlegreen}</span>
          </h3>
          <p className={`${styles.cover_desc} mb-30`}>{desc}</p>
        </div>
      </div>
    </div>
  );
}
