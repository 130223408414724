import * as React from "react";
import {Layout} from "../components/Layout";
import HeaderCompo from "../components/header/Header";
import BlogFirst from "../components/blog/blogmain";
import blogheader from "../images/blogHeader.png";
import BlogCards from "../components/blog/blogcards";
import CoverBanner from "../components/coverBanner/coverBanner";
import blogart from "../images/blogart.png";
import {graphql} from "gatsby";
import SEO from '../components/seo'

export default function blog({data}) {
  return (
    <>
      <Layout>
      <SEO
        title="Blog | Zector"
        image="https://zectorimages.s3.amazonaws.com/zector-banner.jpg"
        url="https://www.zector.io/blog"
        description="Your resource for expert advice on product development and app building for independent entertainers."
      />
        <HeaderCompo
          headerTitle="Blog"
          headerBtn="Tell us about your project"
          headerbg={blogheader}
        />
        <BlogFirst />
        <BlogCards blogsData={data} />
        <CoverBanner
          title="We Are Hear "
          titlegreen="To Help You With"
          desc="Designing solutions in order to pitch your product to investors to raise funding"
          img={blogart}
        />
      </Layout>
    </>
  );
}

export const postQuery = graphql`
  query MyQuery {
    allGhostPost(sort: {fields: published_at, order: DESC}) {
      edges {
        node {
          title
          feature_image
          excerpt
          published_at
          slug
        }
      }
    }
  }
`;
